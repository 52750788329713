import { useState, useEffect } from 'react'

type ScrollDirection = 'up' | 'down'

type UseScrollDirectionProps = {
    initialDirection?: ScrollDirection
    thresholdPixels?: number
    off?: boolean
}

export const useScrollDirection = ({ initialDirection, thresholdPixels, off }: UseScrollDirectionProps = {}) => {
    const [scrollDir, setScrollDir] = useState(initialDirection)

    useEffect(() => {
        const threshold = thresholdPixels || 0
        let lastScrollY = window.pageYOffset
        let ticking = false

        const updateScrollDir = () => {
            const scrollY = window.pageYOffset
            const scrollDidNotExceedsThreshold = Math.abs(scrollY - lastScrollY) < threshold

            if (scrollDidNotExceedsThreshold) {
                ticking = false
                return
            }

            setScrollDir(scrollY > lastScrollY ? 'down' : 'up')
            lastScrollY = scrollY > 0 ? scrollY : 0
            ticking = false
        }

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir)
                ticking = true
            }
        }

        /**
         * Bind the scroll handler if `off` is set to false.
         * If `off` is set to true reset the scroll direction.
         */
        !off ? window.addEventListener('scroll', onScroll) : setScrollDir(initialDirection)

        return () => window.removeEventListener('scroll', onScroll)
    }, [initialDirection, thresholdPixels, off])

    return scrollDir
}
