import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import { Box } from '@wh-components/core/Box/Box'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { ContextLink, findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { ResetLinkButton } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ResetLinkButton/ResetLinkButton'
import { ResetAllButton } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ResetAllButton/ResetAllButton'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { getAllResetLinks } from '@bbx/search-journey/sub-domains/search/lib/getAllResetLinks'
import { TestProps } from '@wh-components/core/common'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { ResponsiveValue } from '@wh-components/system'
import { hasAnySelectedValues } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'

export interface ResetBubblesProps extends TestProps {
    searchResult: SearchResult
    onSearch: SearchCallback
    focusableBubbles?: boolean
    onResetBubbles?: () => void
    display?: ResponsiveValue<string>
    isImageSearch?: boolean
}

export const ResetBubbles: FunctionComponent<ResetBubblesProps> = ({ searchResult, ...props }) => (
    <CommonResetBubbles
        searchResult={searchResult}
        shownResetLinks={getAllResetLinks(searchResult.navigatorGroups, searchResult.verticalId)}
        resetContextLink={findContextLinkWithId('searchResetLink', searchResult.searchContextLinks)}
        taggingData={searchResult.taggingData}
        {...props}
    />
)

interface CommonResetBubblesProps extends TestProps {
    shownResetLinks: {
        label: string
        resetLink: ContextLink
    }[]
    searchResult: SearchResult
    resetContextLink: ContextLink | undefined
    taggingData: TaggingData
    onSearch: SearchCallback
    focusableBubbles?: boolean
    onResetBubbles?: () => void
    flexContainerRef?: React.RefObject<HTMLDivElement>
    expandButton?: React.ReactNode
    display?: ResponsiveValue<string>
    isImageSearch?: boolean
}

export const CommonResetBubbles: FunctionComponent<CommonResetBubblesProps> = ({
    shownResetLinks,
    resetContextLink,
    searchResult,
    taggingData,
    onSearch,
    focusableBubbles = false,
    onResetBubbles,
    testId,
    flexContainerRef,
    expandButton,
    display = 'flex',
    isImageSearch = false,
}) => {
    const isAnythingSelected = hasAnySelectedValues(searchResult.navigatorGroups) || isImageSearch

    if (!isAnythingSelected) {
        return null
    }

    if (isImageSearch && !resetContextLink) {
        resetContextLink = {
            description: 'Suche zur\\u00FCcksetzen',
            id: 'searchResetLink',
            relativePath: '/search/atz/seo/kaufen-und-verkaufen/marktplatz',
            serviceName: 'iad',
            uri: '',
        }
    }

    return (
        <Box
            testId={testId ?? 'reset-bubbles'}
            display={display}
            flexWrap="wrap"
            ref={flexContainerRef}
            css={css`
                margin-bottom: -${(p) => p.theme.space.s}px;

                > button {
                    margin-right: ${(p) => p.theme.space.s}px;
                    margin-bottom: ${(p) => p.theme.space.s}px;

                    /* second-to-last button gets a bigger margin to increase the gap to the "Filter zurücksetzen"-button */

                    &:nth-last-child(2) {
                        margin-right: ${(p) => p.theme.space.m}px;
                    }
                }
            `}
        >
            {shownResetLinks
                .filter(({ resetLink }) => !!resetLink)
                .map(({ label, resetLink }) => (
                    <ResetLinkButton
                        testId={testId}
                        key={`${label}-${resetLink.relativePath}`}
                        label={label}
                        tabIndex={!focusableBubbles ? -1 : undefined}
                        onClick={() => {
                            callActionEvent('search_result_list_reset_click_top', taggingData)
                            onSearch(resetLink)
                        }}
                    />
                ))}

            {expandButton}

            <ResetAllButton
                testId={testId}
                navigatorId="search"
                onSearch={onSearch}
                onClick={() => {
                    onResetBubbles?.()
                }}
                contextLink={resetContextLink}
                fontWeight="bold"
                tabIndex={!focusableBubbles ? -1 : undefined}
            >
                {isImageSearch ? 'Bildersuche zurücksetzen' : 'Filter zurücksetzen'}
            </ResetAllButton>
        </Box>
    )
}
