import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { getAllResetLinks } from '@bbx/search-journey/sub-domains/search/lib/getAllResetLinks'
import { useInterval } from '@wh/common/chapter/hooks/useInterval'
import { Box } from '@wh-components/core/Box/Box'
import { Button } from '@wh-components/core/Button/Button'
import { css } from 'styled-components'
import { Text } from '@wh-components/core/Text/Text'
import { ResetLinkButton } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ResetLinkButton/ResetLinkButton'
import { ResetAllButton } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ResetAllButton/ResetAllButton'
import {
    CommonResetBubbles,
    ResetBubblesProps,
} from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ResetBubbles/ResetBubbles'

const collapsedRows = 2
const resetBubbleExpandButtonEstimatedWidth = 100
const extraBufferMarginLastLine = 30

export const ExpandableResetBubbles: FunctionComponent<ResetBubblesProps> = ({ searchResult, ...props }) => {
    const resetContextLink = findContextLinkWithId('searchResetLink', searchResult.searchContextLinks)
    const allResetLinks = getAllResetLinks(searchResult.navigatorGroups, searchResult.verticalId)
    const [isExpanded, setIsExpanded] = useState(false)
    const [flexContainerWidth, setFlexContainerWidth] = useState(Infinity)
    const [fittingElementCount, setFittingElementCount] = useState(Infinity)

    // we need to get the width of the reset bubble flex container to be able to calculate how many items fit in 2 lines in collapsed state
    // we could instead use the ResizeObserver on the flex container, but that would make a polyfill necessary, and the current solution does not pose much runtime overhead
    const flexContainerRef = useRef<HTMLDivElement>(null)

    useInterval(() => {
        if (flexContainerRef.current) {
            const isDisplayed = flexContainerRef.current.offsetParent !== null
            if (!isDisplayed) {
                // avoid onSetCurrentLeaderboardTop being called with a changed value on each scroll on Phone and Tablet
                return
            }

            setFlexContainerWidth(Math.round(flexContainerRef.current.getBoundingClientRect().width))
        }
    }, 250)

    const concatenatedResetLinkLabels = allResetLinks.map((l) => l.label).join(',')
    useEffect(() => {
        if (flexContainerWidth === Infinity) {
            return
        }

        const flexChildrenWidths = (
            Array.prototype.slice.call(
                // we cant use :scope pseudo selector here as ie11 starts puking
                document.querySelectorAll('#measure-container > *') ?? [],
            ) as HTMLElement[]
        ).map((c) => c.getBoundingClientRect().width)

        // the last element is the reset-all button, this needs to fit always in the last row
        const resetAllButtonWidth = flexChildrenWidths[flexChildrenWidths.length - 1]
        const resetButtonWidths = flexChildrenWidths.slice(0, flexChildrenWidths.length - 1)

        const resetAllButtonWidthIncludingMargin = resetAllButtonWidth + 8
        const resetButtonWidthsIncludingMargin = resetButtonWidths.map((w) => w + 8)

        let fittingElements = 0
        let currentRow = 0
        let currentElementInRow = 0
        let currentRowAvailableRemainingSpace = 0
        for (const width of resetButtonWidthsIncludingMargin) {
            const isNewRow = currentElementInRow === 0
            const isFirstRow = currentRow === 0
            const isLastRow = currentRow === collapsedRows - 1
            if (isNewRow && isFirstRow) {
                currentRowAvailableRemainingSpace = flexContainerWidth
                if (isLastRow) {
                    currentRowAvailableRemainingSpace -= extraBufferMarginLastLine
                    currentRowAvailableRemainingSpace -= resetBubbleExpandButtonEstimatedWidth
                    currentRowAvailableRemainingSpace -= resetAllButtonWidthIncludingMargin
                }
            }
            currentRowAvailableRemainingSpace -= width
            if (currentRowAvailableRemainingSpace < 0) {
                if (isLastRow) {
                    break
                } else {
                    currentRow += 1
                    fittingElements += 1
                    currentElementInRow = 0
                    // retry to fit element in next row
                    const isLastRow2 = currentRow === collapsedRows - 1
                    currentRowAvailableRemainingSpace = flexContainerWidth - width
                    if (isLastRow2) {
                        currentRowAvailableRemainingSpace -= extraBufferMarginLastLine
                        currentRowAvailableRemainingSpace -= resetBubbleExpandButtonEstimatedWidth
                        currentRowAvailableRemainingSpace -= resetAllButtonWidthIncludingMargin
                    }
                    continue
                }
            }
            fittingElements += 1
            currentElementInRow += 1
        }

        setFittingElementCount(fittingElements)
    }, [flexContainerWidth, concatenatedResetLinkLabels])

    const isOverflowing = allResetLinks.length > fittingElementCount
    const shownResetLinks = isExpanded ? allResetLinks : allResetLinks.slice(0, fittingElementCount)

    if (allResetLinks.length <= fittingElementCount && isExpanded) {
        setIsExpanded(false)
    }

    return (
        <Box position="relative">
            <CommonResetBubbles
                searchResult={searchResult}
                shownResetLinks={shownResetLinks}
                resetContextLink={resetContextLink}
                taggingData={searchResult.taggingData}
                flexContainerRef={flexContainerRef}
                expandButton={
                    isOverflowing ? (
                        <Button
                            id="reset-bubble-expand-button"
                            size="xsmall"
                            color="complimentary"
                            variant="outline"
                            aria-label={isExpanded ? 'Filter zuklappen' : 'Filter ausklappen'}
                            onClick={() => setIsExpanded((oldValue) => !oldValue)}
                            css={css`
                                background-color: ${(p) => p.theme.colors.palette.babyseal};
                            `}
                        >
                            <Text truncate={true} fontSize="s" color="palette.raccoon" fontWeight="bold">
                                {isExpanded ? 'Filter zuklappen' : `+ ${allResetLinks.length - fittingElementCount}`}
                            </Text>
                        </Button>
                    ) : null
                }
                {...props}
            />
            {/* hidden container for measuring reset bubble sizes - we need to measure non-rendered elements too, so we need them duplicated here for rendering and measuring */}
            <Box position="absolute" visibility="hidden" zIndex={-1} id="measure-container">
                {allResetLinks
                    .filter(({ resetLink }) => !!resetLink)
                    .map(({ label, resetLink }) => (
                        <ResetLinkButton key={`${label}-${resetLink.relativePath}`} label={label} tabIndex={-1} onClick={() => {}} />
                    ))}

                <ResetAllButton navigatorId="none" onSearch={() => Promise.resolve()} tabIndex={-1} contextLink={resetContextLink}>
                    Filter zurücksetzen
                </ResetAllButton>
            </Box>
        </Box>
    )
}
