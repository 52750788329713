import { NavigatorGroup } from '@bbx/search-journey/common/Navigators'
import { ContextLink } from '@bbx/common/types/contextLinks'
import { getSelectedValuesTextRoomsNavigator } from '@bbx/search-journey/sub-domains/search/components/verticals/real-estate/start-pages/SearchEntryArea/SearchBox'
import { isNotUndefined } from '@wh/common/chapter/lib/functionalHelpers'
import { VerticalId, verticalIdMap } from '@wh/common/chapter/types/verticals'
import { flattenNavigators } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'

export const getAllResetLinks = (navigatorGroups: NavigatorGroup[], verticalId?: VerticalId) => {
    return flattenNavigators(navigatorGroups)
        .map((navigator) => {
            const resetLinks = navigator.selectedValues
                .map((selectedValue) => ({ label: selectedValue.label, resetLink: selectedValue.resetLink }))
                .filter((resetLinkWithLabel) => resetLinkWithLabel.resetLink !== null) as { label: string; resetLink: ContextLink }[]

            // This custom logic is needed for the rooms navigator as it is fake range navigator on the starpage
            // and we want to have the same labels for reset bubbles and selected values
            if (navigator.id === 'nrofrooms' && navigator.selectedValues.length > 0 && navigator.resetAllInformation) {
                return [
                    {
                        label: getSelectedValuesTextRoomsNavigator(navigator),
                        resetLink: navigator.resetAllInformation.resetAllUrl,
                    },
                ]
            }

            if ((navigator.id === 'keyword' && verticalId === verticalIdMap.BAP) || navigator.id === 'paylivery') {
                return undefined
            }

            if (navigator.id === 'category' && navigator.selectedValues.length > 0 && navigator.resetAllInformation) {
                return [
                    {
                        label: navigator?.selectedValues[0].label,
                        resetLink: navigator.resetAllInformation.resetAllUrl,
                    },
                ]
            }

            // fallback in case the api is missing reset links, e.g. WHIAD-25076
            if (resetLinks.length === 0 && navigator.selectedValues.length > 0 && navigator.resetAllInformation) {
                return [{ label: navigator.label, resetLink: navigator.resetAllInformation.resetAllUrl }]
            }

            return resetLinks
        })
        .filter(isNotUndefined)
        .reduce((a, b) => a.concat(b), [])
}
