import React, { FunctionComponent, ReactNode } from 'react'
import { ResponsiveValue, SystemValue } from '@wh-components/system'
import { DynamicRendering } from '@wh/common/chapter/components/DynamicRendering/DynamicRendering'
import { Box } from '@wh-components/core/Box/Box'

export const AdRowDynamicRendering: FunctionComponent<{
    nrOfAds: number
    index: number
    id: string
    className?: string
    children: ReactNode
    height: ResponsiveValue<SystemValue>
}> = ({ nrOfAds, id, index, height, className, children }) => {
    return (
        <DynamicRendering<HTMLDivElement>
            forceRendering={index < nrOfAds}
            offset="250px"
            renderPlaceholder={(ref) => <Box id={id} className={className} ref={ref} height={height} />}
        >
            {children}
        </DynamicRendering>
    )
}
