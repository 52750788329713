import React, { Fragment, FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import {
    CreateSearchAgentButton,
    CreateSearchAgentButtonProps,
} from '@bbx/search-journey/sub-domains/search/components/common/common/CreateSearchAgentButton/CreateSearchAgentButton'

export const CreateSearchAgentButtonWithText: FunctionComponent<CreateSearchAgentButtonProps> = ({
    searchAgentCreateLink,
    searchAgentOptionsLink,
    setSearchAgentUIState,
    testId,
    clickActionEvent,
    taggingData,
}) => {
    if (!searchAgentCreateLink) {
        return null
    }

    return (
        <Box paddingBottom="m" display={{ phone: 'none', tablet: 'block' }}>
            <CreateSearchAgentButtonWithTextInner
                searchAgentCreateLink={searchAgentCreateLink}
                searchAgentOptionsLink={searchAgentOptionsLink}
                setSearchAgentUIState={setSearchAgentUIState}
                testId={testId}
                clickActionEvent={clickActionEvent}
                taggingData={taggingData}
            />
        </Box>
    )
}

export const CreateSearchAgentButtonWithTextInner: FunctionComponent<CreateSearchAgentButtonProps> = ({
    searchAgentCreateLink,
    searchAgentOptionsLink,
    setSearchAgentUIState,
    testId,
    clickActionEvent,
    taggingData,
}) => {
    if (!searchAgentCreateLink) {
        return null
    }

    return (
        <Fragment>
            <CreateSearchAgentButton
                searchAgentCreateLink={searchAgentCreateLink}
                searchAgentOptionsLink={searchAgentOptionsLink}
                setSearchAgentUIState={setSearchAgentUIState}
                testId={testId}
                clickActionEvent={clickActionEvent}
                taggingData={taggingData}
            />
            <Box marginTop="s">
                <Text as="p" textAlign="center" fontSize="s">
                    Neue Anzeigen per Benachrichtigung!
                </Text>
            </Box>
        </Fragment>
    )
}
