import React, { Fragment, FunctionComponent } from 'react'
import { BreadcrumbsItem } from '@bbx/common/types/ad-detail/AdvertDetails'
import { Box } from '@wh-components/core/Box/Box'
import { Breadcrumbs } from '@bbx/search-journey/common/components/Breadcrumbs/Breadcrumbs'
import { SpaceProps } from '@wh-components/system/space'

interface DesktopBreadcrumbsProps extends SpaceProps {
    breadcrumbs?: BreadcrumbsItem[] | null
}

export const DesktopBreadcrumbs: FunctionComponent<DesktopBreadcrumbsProps> = ({ breadcrumbs, ...props }) => {
    return (
        <Fragment>
            {breadcrumbs && (
                <Box display={{ phone: 'none', tablet: 'block' }} paddingBottom="m" {...props}>
                    <Breadcrumbs breadcrumbsList={breadcrumbs} />
                </Box>
            )}
        </Fragment>
    )
}
