import React, { Fragment, FunctionComponent, ReactElement } from 'react'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { ResultListRenderSlotConfig, resultListRenderSlotConfigForGivenSearchResult } from '@wh/common/digital-advertising/config'
import { ResultListContentAdRenderSlot } from '@wh/common/digital-advertising/components/ResultListContentAdRenderSlot/ResultListContentAdRenderSlot'
import { intersperseWithIndexes } from '@wh/common/chapter/lib/functionalHelpers'
import { NewAdsSeparator } from './NewAdsSeparator'
import { css } from 'styled-components'
import { DEFAULT_FLOATING_HEADER_HEIGHT_PHONE } from '../ResultListHeaderSticky/ResultListHeaderStickySmall'
import { FIXED_HEADER_HEIGHT_PHONE } from '@wh/common/chapter/lib/config/constants'
import {
    DEFAULT_FLOATING_HEADER_HEIGHT_DESKTOP,
    DEFAULT_FLOATING_HEADER_HEIGHT_TABLET,
} from '../ResultListHeaderSticky/ResultListHeaderStickyMediumLarge'
import { ViewMode } from '@bbx/search-journey/common/lib/viewModeHelper'
import { useScreenSize } from '@wh/common/chapter/components/UserAgentProvider/useUserAgent'

interface ResultListAdRowsProps {
    advertSummaries: AdvertSummary[]
    newAdsSeparatorPosition: number | null
    page: number
    TopAdsComponent: ReactElement
    children: ReactElement[]
    viewMode?: ViewMode
}

export const ResultListAdRows: FunctionComponent<ResultListAdRowsProps> = ({
    advertSummaries,
    newAdsSeparatorPosition,
    page,
    TopAdsComponent,
    children,
    viewMode = 'list',
}) => {
    const screen = useScreenSize()
    const isDesktop = screen === 'desktop'

    const renderSlots = resultListRenderSlotConfigForGivenSearchResult(page, advertSummaries.length).map((config) => {
        return {
            insertBeforeIndex: resolveBeforeAdIndex(viewMode, isDesktop, config),
            element: (
                <ResultListContentAdRenderSlot
                    key={config.renderSlotId}
                    renderSlotId={config.renderSlotId}
                    loadingHeight={config.loadingHeight}
                    padding="m"
                />
            ),
        }
    })

    const topAdsComponent = [{ insertBeforeIndex: viewMode === 'list' ? 2 : 8, element: TopAdsComponent }]
    const newAdsSeparator = getNewAdsSeparator(newAdsSeparatorPosition, page)
    const rows = intersperseWithIndexes(children, [...renderSlots, ...topAdsComponent, ...newAdsSeparator])

    return <Fragment>{rows}</Fragment>
}

// INFO scroll-margin-top adjusts the scroll offset when jumping to an ad via fragment or `scrollIntoView()`
// when the sticky header is higher than the default height, it will overlap the ad slightly, that is ok
// and when there is no sticky header, the scroll margin will still be there, that is ok too
export const scrollMargin = css`
    scroll-margin-top: ${DEFAULT_FLOATING_HEADER_HEIGHT_PHONE + FIXED_HEADER_HEIGHT_PHONE}px;

    ${(p) => p.theme.media.tablet} {
        scroll-margin-top: ${DEFAULT_FLOATING_HEADER_HEIGHT_TABLET}px;
    }

    ${(p) => p.theme.media.desktop} {
        scroll-margin-top: ${DEFAULT_FLOATING_HEADER_HEIGHT_DESKTOP}px;
    }
`

const getNewAdsSeparator = (newAdsSeparatorPosition: number | null, page: number) => {
    return newAdsSeparatorPosition === null
        ? []
        : [
              {
                  insertBeforeIndex: newAdsSeparatorPosition,
                  element: <NewAdsSeparator key="new-ads-separator" allAdsNew={newAdsSeparatorPosition === 0 && page === 1} />,
              },
          ]
}

const resolveBeforeAdIndex = (viewMode: string, isDesktop: boolean, config: ResultListRenderSlotConfig[number]) => {
    if (viewMode === 'grid') {
        if (isDesktop && config.showBeforeAdIndexGridViewDesktop) {
            return config.showBeforeAdIndexGridViewDesktop
        }
        return config.showBeforeAdIndexGridView
    } else {
        if (isDesktop && config.showBeforeAdIndexDesktop) {
            return config.showBeforeAdIndexDesktop
        } else {
            return config.showBeforeAdIndex
        }
    }
}

export const MemoizedResultListAdRows = React.memo(ResultListAdRows)
