import React, { FunctionComponent, RefObject } from 'react'
import { useIntersectionObserver } from '@wh/common/chapter/hooks/useIntersectionObserver'
import { BaseStickyHeader } from '@bbx/search-journey/common/components/StickyHeader/BaseStickyHeader'
import { SearchResult } from '../../../../../../common/SearchResult'
import { Box } from '@wh-components/core/Box/Box'
import { Button } from '@wh-components/core/Button/Button'
import { FIXED_HEADER_HEIGHT_PHONE } from '@wh/common/chapter/lib/config/constants'
import { useScrollDirection } from '@wh/common/chapter/hooks/useScrollDirection'
import { theme } from '@wh-components/core/theme'
import { css } from 'styled-components'
import {
    zIndexStickyHeaderSmallInFrontOfHeader,
    zIndexStickyHeaderSmallBelowHeader,
} from '@bbx/search-journey/sub-domains/search/lib/zIndices'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'

interface ResultListHeaderStickySmallProps {
    regularNonStickyHeaderVisibilityTrackingRef: RefObject<HTMLDivElement>
    resultListVisibilityTrackingRef: RefObject<HTMLDivElement>
    testIdPrefix: string
    title: string
    searchResult: SearchResult
    setShowNavigatorSidebar: (value: boolean) => void
    taggingData: TaggingData
}

export const DEFAULT_FLOATING_HEADER_HEIGHT_PHONE = FIXED_HEADER_HEIGHT_PHONE

const transitionDuration = 300

export const ResultListHeaderStickySmall: FunctionComponent<ResultListHeaderStickySmallProps> = ({
    regularNonStickyHeaderVisibilityTrackingRef,
    resultListVisibilityTrackingRef,
    testIdPrefix,
    setShowNavigatorSidebar,
    searchResult,
    title,
    taggingData,
}) => {
    // we cannot use the same solution as in AdDetailStickyHeader where we only have one intersection observer and check for `boundingClientRect.top < rootBounds.top` in the callback, because it can easily happen that the page jumps from the top to an ad row when an url `#fragment` is set, and when the observed element would directly go from being invisible below the viewport to being invisible above the viewport, the callback would not trigger
    const [isRegularNonStickyHeaderVisible] = useIntersectionObserver(regularNonStickyHeaderVisibilityTrackingRef, {
        triggerOnce: false,
        threshold: 0.16,
        initialIsInView: true,
    })
    const [isResultListVisible] = useIntersectionObserver(resultListVisibilityTrackingRef, { triggerOnce: false })
    const showStickyHeader = !isRegularNonStickyHeaderVisible && isResultListVisible

    const scroll = useScrollDirection({ initialDirection: 'up', thresholdPixels: 6 })
    const isScrollUp = scroll === 'up'
    const isScrollDown = useScrollDirection({ initialDirection: 'up', thresholdPixels: 6 }) === 'down'

    return (
        <BaseStickyHeader
            testId={`${testIdPrefix}-${showStickyHeader ? 'visible' : 'hidden'}-scroll-${
                isScrollUp ? 'up' : isScrollDown ? 'down' : 'none'
            }`}
            showStickyHeader={showStickyHeader}
            hideAnimationDuration={transitionDuration}
            css={css`
                z-index: ${!showStickyHeader
                    ? theme.zIndices.stickyContent
                    : isScrollUp
                    ? zIndexStickyHeaderSmallBelowHeader
                    : zIndexStickyHeaderSmallInFrontOfHeader};
                transform: ${showStickyHeader ? `translateY(${isScrollUp ? FIXED_HEADER_HEIGHT_PHONE : 0}px)` : 'translateY(-110%)'};
                transition:
                    opacity ${transitionDuration / 1000}s 0s,
                    transform ${transitionDuration / 1000}s 0s,
                    z-index ${transitionDuration / 1000}s 0s;
                ${(p) => p.theme.media.print} {
                    display: none;
                }
            `}
        >
            <Box
                height={FIXED_HEADER_HEIGHT_PHONE} // make sure that this sticky header fully covers the fixed header
                display="flex"
                paddingHorizontal="m"
                paddingVertical="s"
                backgroundColor={{
                    phone: 'palette.babyseal',
                    tablet: 'transparent',
                }}
            >
                <Button
                    size="small"
                    width="70px"
                    testId="sticky-header-filter-button-small"
                    onClick={() => {
                        setShowNavigatorSidebar(true)
                        return callActionEvent('search_result_list_filter_small_click', taggingData)
                    }}
                >
                    Filtern
                </Button>
                <Box marginLeft="s" fontSize="xs" lineHeight="15px">
                    {searchResult.heading && <Box>{searchResult.heading}:</Box>}
                    {title && <Box fontWeight="bold">{title}</Box>}
                </Box>
            </Box>
            <hr
                aria-hidden="true"
                css={css`
                    margin: 0;
                `}
            />
        </BaseStickyHeader>
    )
}
