import React, { FunctionComponent, RefObject } from 'react'
import { useIntersectionObserver } from '@wh/common/chapter/hooks/useIntersectionObserver'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import styled, { DataAttributes, css } from 'styled-components'
import { rgba } from 'polished'
import { StickyHeader } from '@bbx/search-journey/common/components/StickyHeader/StickyHeader'
import { ContextLink } from '@bbx/common/types/contextLinks'
import {
    CreateSearchAgentButton,
    SearchAgentUIState,
} from '@bbx/search-journey/sub-domains/search/components/common/common/CreateSearchAgentButton/CreateSearchAgentButton'
import { SearchResult } from '../../../../../../common/SearchResult'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { scrollToTop } from '@wh/common/chapter/lib/commonHelpers'
import ArrowUpIcon from '@wh-components/icons/ArrowUp'
import { testIdAttribute, TestProps } from '@wh-components/core/common'
import { ExpandableResetBubbles } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ResetBubbles/ExpandableResetBubbles'

interface ResultListHeaderStickyMediumLargeProps {
    regularNonStickyHeaderVisibilityTrackingRef: RefObject<HTMLDivElement>
    resultListVisibilityTrackingRef: RefObject<HTMLDivElement>
    searchAgentCreateLink: ContextLink | undefined
    searchAgentOptionsLink: ContextLink | undefined
    setSearchAgentUIState: (value: SearchAgentUIState) => void
    testId: string
    title: string
    searchResult: SearchResult
    onSearch: SearchCallback
}

const ResultListHeaderStickyHeader = styled(Text)`
    font-size: ${(p) => p.theme.fontSizes.s};
    font-weight: ${(p) => p.theme.fontWeights.bold};
    margin-bottom: ${(p) => p.theme.space.xs}px;
`

const NachObenButton = styled.button.attrs<TestProps & DataAttributes>(testIdAttribute)`
    padding: 0 ${(p) => p.theme.space.s}px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border: 0;
    color: ${(p) => p.theme.colors.palette.raccoon};
    background-color: ${(p) => p.theme.colors.palette.babyseal};
    line-height: 1.5;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    text-decoration: none;
    user-select: none;
    white-space: nowrap;
    vertical-align: middle;
    transition: all 0.2s;
    cursor: pointer;
    height: 100%;
    width: 100%;
`

export const DEFAULT_FLOATING_HEADER_HEIGHT_DESKTOP = 72
export const DEFAULT_FLOATING_HEADER_HEIGHT_TABLET = 72

export const ResultListHeaderStickyMediumLarge: FunctionComponent<ResultListHeaderStickyMediumLargeProps> = ({
    regularNonStickyHeaderVisibilityTrackingRef,
    resultListVisibilityTrackingRef,
    searchAgentCreateLink,
    searchAgentOptionsLink,
    setSearchAgentUIState,
    testId,
    title,
    searchResult,
    onSearch,
}) => {
    // we cannot use the same solution as in AdDetailStickyHeader where we only have one intersection observer and check for `boundingClientRect.top < rootBounds.top` in the callback, because it can easily happen that the page jumps from the top to an ad row when an url `#fragment` is set, and when the observed element would directly go from being invisible below the viewport to being invisible above the viewport, the callback would not trigger
    const [isRegularNonStickyHeaderVisible] = useIntersectionObserver(regularNonStickyHeaderVisibilityTrackingRef, {
        triggerOnce: false,
        threshold: 0.16,
        initialIsInView: true,
    })
    const [isResultListVisible] = useIntersectionObserver(resultListVisibilityTrackingRef, { triggerOnce: false })
    const showStickyHeader = !isRegularNonStickyHeaderVisible && isResultListVisible

    return (
        <StickyHeader testId={testId} showStickyHeader={showStickyHeader}>
            <Box
                display="flex"
                aria-hidden={true}
                backgroundColor="palette.white"
                css={css`
                    border-bottom: 1px solid ${(p) => p.theme.colors.palette.owl};
                    box-shadow: 0 4px 8px -4px ${(p) => rgba(p.theme.colors.palette.black, 0.3)};
                `}
            >
                <Box display="flex" flex="1 1 100%" paddingLeft="m" paddingVertical="s">
                    <Box display="flex" alignItems="flex-start" flex="0 0 250px">
                        <CreateSearchAgentButton
                            testId={`${testId}-search-agent-button`}
                            size="large"
                            tabIndex={-1}
                            searchAgentCreateLink={searchAgentCreateLink}
                            searchAgentOptionsLink={searchAgentOptionsLink}
                            setSearchAgentUIState={setSearchAgentUIState}
                            clickActionEvent="search_result_list_search_agent_click_sticky"
                            taggingData={searchResult.taggingData}
                        />
                    </Box>
                    <Box display="flex" flexDirection="column" minWidth="0" flex="1 1 100%" paddingLeft="m" justifyContent="center">
                        <ResultListHeaderStickyHeader>{title}</ResultListHeaderStickyHeader>
                        <ExpandableResetBubbles
                            testId={`${testId}-reset-bubbles`}
                            focusableBubbles={false}
                            onResetBubbles={scrollToTop}
                            searchResult={searchResult}
                            onSearch={onSearch}
                        />
                    </Box>
                </Box>
                <Box display="flex" alignItems="center">
                    <NachObenButton testId={`${testId}-scroll-to-top-button`} tabIndex={-1} onClick={() => scrollToTop()}>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <ArrowUpIcon size="large" color="palette.raccoon" />
                            <Text lineHeight={1}>NACH</Text>
                            <Text lineHeight={1}>OBEN</Text>
                        </Box>
                    </NachObenButton>
                </Box>
            </Box>
        </StickyHeader>
    )
}
