import { BBX_MAX_PAGE_PARAM } from '@wh/common/chapter/lib/config/constants'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'

export const getCurrentAndTotalPages = (currentSearchResult: SearchResult, hasHits: boolean) => {
    const calcTotalPagesCount = (search: SearchResult, page: number) => Math.max(Math.ceil(search.rowsFound / search.rowsRequested), page)

    const currentPage = currentSearchResult.pageRequested

    const totalPages = Math.min(hasHits ? calcTotalPagesCount(currentSearchResult, currentPage) : 1, BBX_MAX_PAGE_PARAM)
    return { currentPage, totalPages }
}
