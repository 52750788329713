import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { AdsPerPage } from '@bbx/search-journey/sub-domains/search/components/common/common/AdsPerPage/AdsPerPage'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { ResultListPagination } from '@bbx/search-journey/sub-domains/search/components/common/result-list/Pagination/ResultListPagination'
import { Box } from '@wh-components/core/Box/Box'
import { DynamicRendering } from '@wh/common/chapter/components/DynamicRendering/DynamicRendering'
import { scrollToTop } from '@wh/common/chapter/lib/commonHelpers'
import React, { RefObject } from 'react'

interface ResultListBottomNavigationProps {
    totalPages: number
    currentPage: number
    triggerSearch: SearchCallback
    searchResult: SearchResult
    showAdsPerPage?: boolean
    paginationRef?: RefObject<HTMLDivElement>
    adsPerPageLabel?: string
}

export const ResultListBottomNavigation: React.FC<ResultListBottomNavigationProps> = ({
    searchResult,
    totalPages,
    currentPage,
    triggerSearch,
    showAdsPerPage = true,
    paginationRef,
    adsPerPageLabel,
}) => {
    return (
        <Box ref={paginationRef}>
            <DynamicRendering<HTMLDivElement> renderPlaceholder={(ref) => <Box ref={ref} />}>
                <Box
                    display={searchResult.rowsFound > 0 ? 'flex' : 'none'}
                    justifyContent={{ phone: 'center', tablet: 'space-between' }}
                    paddingVertical={{ phone: 'm', tablet: 's' }}
                >
                    <ResultListPagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        searchResult={searchResult}
                        onSearch={async (searchLink, additionalParams) => {
                            scrollToTop()
                            await triggerSearch(searchLink, additionalParams, { disableScrollToTopOnSmall: true })
                        }}
                        testId="pagination-bottom"
                    />
                    {showAdsPerPage && (
                        <Box display={{ phone: 'none', tablet: 'block' }}>
                            <AdsPerPage
                                onSearch={async (searchLink, additionalParams) => {
                                    scrollToTop()
                                    await triggerSearch(searchLink, additionalParams, { disableScrollToTopOnSmall: true })
                                }}
                                label={adsPerPageLabel}
                                searchResult={searchResult}
                                testId="adsperpage-bottom"
                                rowsRequested={searchResult.rowsRequested}
                                fontSize="s"
                            />
                        </Box>
                    )}
                </Box>
            </DynamicRendering>
        </Box>
    )
}
