import React, { FunctionComponent } from 'react'
import { Text } from '@wh-components/core/Text/Text'
import { Box } from '@wh-components/core/Box/Box'
import CheckCircleIcon from '@wh-components/icons/CheckCircle'

interface NewAdsSeparatorProps {
    allAdsNew: boolean
}

export const NewAdsSeparator: FunctionComponent<NewAdsSeparatorProps> = (props) => (
    <Box display="flex" flexDirection="column" alignItems="center" marginVertical="s">
        {/* marginHorizontal should be the same as the regular ad separator, see resultListBottomBorderAfterElement */}
        <Box display="flex" flexDirection="row" alignItems="center" width="100%" paddingHorizontal="s" marginBottom="2px">
            <Line />
            <Circle />
            <Line />
        </Box>
        <Text as="p" color="palette.coral" fontWeight="bold" fontSize="s">
            Alle neuen Anzeigen gesehen
        </Text>
        {props.allAdsNew ? null : (
            <Text as="p" color="palette.raccoon" fontSize="s">
                Schau dir hier alle weiteren Anzeigen an
            </Text>
        )}
    </Box>
)

const Line = () => <Box height="1px" backgroundColor="palette.coral" flex="1 1 auto" />

const Circle = () => <CheckCircleIcon size="large" color="palette.coral" marginHorizontal="xs" />
