import React, { FunctionComponent, ReactNode } from 'react'
import { css } from 'styled-components'
import { Box } from '@wh-components/core/Box/Box'
import { Heading } from '@wh-components/core/Heading/Heading'
import { NavigatorGroup } from '@bbx/search-journey/common/Navigators'
import { isNavigatorAvailable } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'

export interface NavigatorGroupLayoutProps {
    group: NavigatorGroup
    groupLabelId: string
    navigators: ReactNode
}

export const NavigatorGroupLayout: FunctionComponent<NavigatorGroupLayoutProps> = ({ group, groupLabelId, navigators }) => {
    if (!group.navigatorList.some(isNavigatorAvailable)) {
        return null
    }

    return (
        <Box border="owl" borderRadius={{ tablet: 'm' }} marginBottom={{ phone: -1, tablet: 'l' }}>
            {group.label && (
                <Box
                    borderTopRadius={{ tablet: 'm' }}
                    paddingHorizontal={{ phone: 'm', tablet: 's' }}
                    paddingVertical={{ phone: 'sm', tablet: 'sm' }}
                    backgroundColor="palette.babyseal"
                    borderBottom="owl"
                >
                    <Heading
                        id={`navigator-group-${groupLabelId}`}
                        testId={`navigator-group-${groupLabelId}`}
                        level={2}
                        fontSize="m"
                        color="palette.elephant"
                        text={group.label}
                        css={css`
                            text-transform: uppercase;
                        `}
                    />
                </Box>
            )}

            <Box
                backgroundColor={group.label === '' ? 'palette.polarbear' : { tablet: 'palette.polarbear' }}
                borderBottomRadius={{ tablet: 'm' }}
                padding={{ phone: 'm', tablet: 's' }}
            >
                {navigators}
            </Box>
        </Box>
    )
}
