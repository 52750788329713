import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import {
    CreateSearchAgentButton,
    SearchAgentUIState,
} from '@bbx/search-journey/sub-domains/search/components/common/common/CreateSearchAgentButton/CreateSearchAgentButton'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { Box } from '@wh-components/core/Box/Box'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { Heading } from '@wh-components/core/Heading/Heading'
import { Text } from '@wh-components/core/Text/Text'
import error_img from '@wh/common/chapter/static_hashed/img/errors/error.svg'
import React, { Fragment, FunctionComponent } from 'react'
import styled from 'styled-components'

interface EmptyResultListProps {
    searchResult: SearchResult
    onSearch: SearchCallback
    setSearchAgentUIState: (value: SearchAgentUIState) => void
}

export const EmptyResultList: FunctionComponent<EmptyResultListProps> = ({ onSearch, searchResult, setSearchAgentUIState }) => {
    const resetContextLink = findContextLinkWithId('searchResetLink', searchResult.searchContextLinks)
    const searchAgentCreateLink = findContextLinkWithId('searchAgentCreateLink', searchResult.searchContextLinks)
    const searchAgentOptionsLink = findContextLinkWithId('searchAgentOptionsLink', searchResult.searchContextLinks)

    const triggerSearchReset = async () => {
        if (resetContextLink) {
            await onSearch(resetContextLink)
        }
    }

    return (
        <Box height="100%">
            <Box padding="l" display="flex" flexDirection="column" justifyContent="center" position="sticky" top={100} testId="empty-state">
                <EmptyResultListMainImage src={error_img} alt="" />
                <Box marginBottom="s">
                    <Heading text="Leider hat deine Suche keine Treffer erzielt" level={2} fontSize="xl" textAlign="center" />
                </Box>
                <Box>
                    <Text as="p" textAlign="center">
                        Verwende weniger Filter oder überprüfe, ob du alles richtig geschrieben hast.
                    </Text>
                </Box>
                {resetContextLink && (
                    <Box display="flex" justifyContent="center" marginBottom="m">
                        <ButtonWithLinkStyle
                            fontSize="m"
                            onClick={triggerSearchReset}
                            color="palette.primary.main"
                            fontWeight="bold"
                            marginRight="xs"
                        >
                            Suche zurücksetzen
                        </ButtonWithLinkStyle>
                    </Box>
                )}

                {searchAgentCreateLink && (
                    <Fragment>
                        <Box display="flex" justifyContent="center">
                            <Text as="p" textAlign="center" maxWidth="400px">
                                Vielleicht gibt’s die passende Anzeige einfach etwas später. Ein Suchagent benachrichtigt dich automatisch.
                            </Text>
                        </Box>

                        <Box display={{ phone: 'none', tablet: 'flex' }} justifyContent="center">
                            <CreateSearchAgentButton
                                searchAgentCreateLink={searchAgentCreateLink}
                                searchAgentOptionsLink={searchAgentOptionsLink}
                                setSearchAgentUIState={setSearchAgentUIState}
                                size="small"
                                testId="create-search-agent-button-link-empty-result"
                                clickActionEvent="search_result_list_search_agent_click_empty_result"
                                taggingData={searchResult.taggingData}
                                buttonVariant="link"
                            />
                        </Box>
                    </Fragment>
                )}
            </Box>
        </Box>
    )
}

export const EmptyResultListMainImage = styled.img`
    height: 200px;
    margin-bottom: 16px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 200px;
        margin: 0 auto;
    }
`
