import React, { FunctionComponent } from 'react'
import { Pagination } from '@wh-components/core/Pagination/Pagination'
import { ResponsiveGuaranteedValue } from '@wh-components/system'
import { useCreateResponsiveGuaranteedValueWithSSRFallback } from '@wh/common/chapter/components/UserAgentProvider/useUserAgent'

type SSRPaginationProps = Omit<React.ComponentProps<typeof Pagination>, 'arrows' | 'compact' | 'range'> & {
    arrows?: ResponsiveGuaranteedValue<boolean>
    compact?: ResponsiveGuaranteedValue<boolean>
    range?: ResponsiveGuaranteedValue<number>
}

export const SSRPagination: FunctionComponent<SSRPaginationProps> = (props) => {
    const arrows = useCreateResponsiveGuaranteedValueWithSSRFallback(props.arrows)
    const compact = useCreateResponsiveGuaranteedValueWithSSRFallback(props.compact)
    const range = useCreateResponsiveGuaranteedValueWithSSRFallback(props.range)

    return <Pagination {...props} arrows={arrows} compact={compact} range={range} />
}
