import React, { FunctionComponent, useCallback } from 'react'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { concatPathWithQueryParams, getQueryParam, removeUrlParameter } from '@wh/common/chapter/lib/urlHelpers'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { TestProps } from '@wh-components/core/common'
import { SSRPagination } from '@wh/common/chapter/components/SSRPagination'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { removeUndefined } from '@wh/common/chapter/lib/functionalHelpers'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'
import { buildSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { unixTimeInMilliseconds } from '@wh/common/chapter/lib/formatter'

interface ResultListPaginationProps {
    totalPages: number
    currentPage: number
    onSearch: SearchCallback
    searchResult: SearchResult
}

export const ResultListPagination: FunctionComponent<ResultListPaginationProps & TestProps> = ({
    testId,
    totalPages,
    currentPage,
    onSearch,
    searchResult,
}) => {
    const trackEvent = useOptimizelyTrack()

    const triggerPageSearch = async (page: number) => {
        const url = findContextLinkWithId('searchBaseLink', searchResult.searchContextLinks)

        if (url?.relativePath) {
            url.relativePath = removeUrlParameter(url.relativePath, 'page')
            const adSeparatorSeenQueryParam = getQueryParam(url.relativePath, 'adSeparatorSeen')
            url.relativePath = removeUrlParameter(url.relativePath, 'adSeparatorSeen')

            const lastViewedSearchAgentInMilliseconds = lastViewedSearchAgentInMillisecondsParameterForPaging(
                searchResult.lastUserAlertViewedDate,
            )

            const wasPreviousPageTrue = adSeparatorSeenQueryParam === 'true'
            const adSeparatorSeen =
                (searchResult.newAdsSeparatorPosition !== null && typeof searchResult.newAdsSeparatorPosition !== 'undefined') ||
                wasPreviousPageTrue

            const additionalParams = removeUndefined({
                page: page.toString(),
                adSeparatorSeen: adSeparatorSeen ? 'true' : undefined,
                lastViewedSearchAgentInMilliseconds,
            })
            await onSearch(url, additionalParams)
        }

        if (searchResult.verticalId === verticalIdMap.BAP) {
            trackEvent('resultListPaginationClick')
        }
    }

    const uri = buildSeoUrl(findContextLinkWithId('resultListSeoNavigatorLink', searchResult.searchContextLinks)?.relativePath)
    const hrefBuilder = useCallback(
        (page: number) => {
            if (!uri) {
                return ''
            }

            const urlWithoutPage = removeUrlParameter(uri, 'page')
            if (page === 1) {
                return urlWithoutPage
            } else {
                return concatPathWithQueryParams(urlWithoutPage, { page: page.toString() })
            }
        },
        [uri],
    )

    return totalPages > 1 ? (
        <SSRPagination
            size={{ phone: 'medium', tablet: 'small' }}
            totalPages={totalPages}
            currentPage={currentPage}
            range={{ phone: 2, desktop: 4 }}
            arrows={{ phone: true, tablet: false }}
            compact={{ phone: true, tablet: false }}
            startEllipsis={true}
            link={hrefBuilder}
            onClick={triggerPageSearch}
            testId={testId}
        />
    ) : null
}

/**
 * This function returns the lastViewedSearchAgentInMilliseconds parameter value to be appended to the search url for paging (paging only, not filtering or other actions).
 * When the api receives this parameter, it will use this timestamp instead of the last viewed date stored in BDS.
 * This allows the user to page in a Search Agent result list, and see the new ads separator in the correct place on a later page.
 * See UserAlertControllerImpl#getLastViewedDate in the api code: https://gitlab.willhaben.at/willhaben/shared/iad/-/blob/develop/remote/api/src/main/java/at/willhaben/iad/remote/v2/controller/impl/UserSearchAgentControllerImpl.java#L311
 * The apps do not need to append this parameter client side, because they use infinite scrolling, and the api adds the parameter to the next-page context link.
 * We decided against modifying the api to also add the parameter to other context links, because the searchBaseLink needs to NOT contain the parameter, as the apps use it for refresh, and the separator should not show up in that case anymore (or show a new separator in the case new ads came in).
 */
export const lastViewedSearchAgentInMillisecondsParameterForPaging = (lastUserAlertViewedIsoDateString: string | null) => {
    if (!lastUserAlertViewedIsoDateString) {
        return undefined
    }

    return `${unixTimeInMilliseconds(lastUserAlertViewedIsoDateString)}`
}
