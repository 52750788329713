import { useEffect, useState } from 'react'

/*
 Triggers a re-rendering if the screen-height changes (hardcoded to 450px, iPhone SE height > 500px)
 Needed in the FilterSidebar because the height of the footer is calculated via a ref and needs to be updated
 */
export const useRerenderIfKeyboardIsOpened = () => {
    const [_isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>(false)

    useEffect(() => {
        const mediaQueryList = window.matchMedia('(max-height: 450px)')
        const listener = ({ matches }: MediaQueryListEvent) => setIsKeyboardOpen(matches)

        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener('change', listener)
        } else {
            mediaQueryList.addListener(listener)
        }

        return () => {
            if (mediaQueryList.removeEventListener) {
                mediaQueryList.removeEventListener('change', listener)
            } else {
                mediaQueryList.removeListener(listener)
            }
        }
    }, [])
}
