import { useEffect, useState } from 'react'

export const useNavigatorSidebar = (screenSize: 'tablet' | 'desktop' | 'phone') => {
    const [showNavigatorSidebar, setShowNavigatorSidebar] = useState<boolean>(false)

    useEffect(() => {
        if (screenSize !== 'phone' && showNavigatorSidebar) {
            setShowNavigatorSidebar(false)
        }
    }, [screenSize, showNavigatorSidebar])

    return { showNavigatorSidebar, setShowNavigatorSidebar }
}
